import React, { useState, useMemo, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useSelector } from "react-redux";
// import { toast } from "react-hot-toast/headless";

import { getReduxStore } from "../state/configureStore";
import { getBoundActions } from "../state/actions";
import ProductFinderBlock from "@col-care/common/blocks/product-finder/ProductFinder";
import Button from "../components/button/Button";
import Modal from "../components/modal/Modal";

import { getHostname } from "@col-care/common/helpers";
import { getStoreType } from "@col-care/common/common";
import { posthogEvents } from "@col-care/common/utilities/constants";
import {
  capturePosthogEvent,
  capturePosthogLink,
} from "@col-care/common/utilities/index";

import * as LocationsStyles from "../styles/locations.module.css";

const Locations = (props) => {
  const state = useSelector((state) => state);
  const cartData = useSelector((state) => state.session.cartData);
  const store = getReduxStore("init", state.router.location.pathname).store;
  const actions = getBoundActions(store.dispatch);
  const posthog = props?.posthog;

  const locations = state.hydrate.databagData.locations;
  const blocks = state.hydrate.siteConfigData?.blocks;

  const productFinderSettings = blocks
    ? blocks["product-finder"]?.component_data?.product_finder
    : null;

  const locationsByStoreId = useMemo(() => {
    return locations
      ? locations.reduce((a, v) => {
          a[v.content.vendor_integrations.jane_rec_id] = v;
          return a;
        }, {})
      : {};
  }, [locations]);

  const currentStore = state.session.cartData.store.janeId
    ? locationsByStoreId[state.session.cartData.store.janeId]
    : null;

  const [showLocationUpdateModal, setShowLocationUpdateModal] = useState(null);

  return (
    <>
      <section className={LocationsStyles.location_area}>
        <div style={{ textAlign: "center", paddingTop: "50px" }}>
          <h1>TGS Locations</h1>
          {!state.session.cartData.store.id && (
            <p>Please select a store to start shopping.</p>
          )}
          <AnchorLink
            to={`#locations-listing`}
            onClick={() => {
              capturePosthogLink({
                eventName: `${posthogEvents.PRODUCT_FINDER} : Or Browse All Locations button clicked`,
                title: " Or Browse All Locations",
                to: "#locations-listing",
                posthog,
              });
            }}
          >
            <h3 className={LocationsStyles.browse_locations}>
              Browse All Locations
            </h3>
          </AnchorLink>
          <div>
            {typeof window === "object" && productFinderSettings && (
              <ProductFinderBlock
                variant="tgs"
                key={productFinderSettings.global_id}
                id={productFinderSettings.global_id}
                hostname={getHostname()}
                apiPath={`/page-data/___api/zip-to-coordinates`}
                zoom={parseInt(productFinderSettings.map_settings.zoom)}
                center={{
                  lat: parseFloat(productFinderSettings.map_settings.lat),
                  lng: parseFloat(productFinderSettings.map_settings.lng),
                }}
                googleMapsApiKey={process.env.GHUB_GOOGLE_PLACES_API_KEY}
                actions={actions}
                cartData={state.session.cartData}
                setShowLocationUpdateModal={setShowLocationUpdateModal}
                posthog={posthog}
              />
            )}
          </div>
        </div>
      </section>

      <section
        className={LocationsStyles.location_section}
        id={`locations-listing`}
      >
        <div className={``}>
          <ul className={LocationsStyles.location_group}>
            {getLocationsMarkup(
              actions,
              locations,
              state.session.cartData,
              setShowLocationUpdateModal,
              posthog
            )}
          </ul>
        </div>
      </section>

      <Modal
        buttonOpts={["tgssmall", "tgsTransparent"]}
        modalTitle={`Change Locations?`}
        variant={`location_update`}
        dismissModal={() => {
          setShowLocationUpdateModal(false);
          capturePosthogEvent({
            eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_CHANGE_MODAL_CLOSED}`,
            databag: { cartData },
            posthog,
          });
        }}
        extraClasses="modal-dialog-location-update"
        hide={!showLocationUpdateModal}
        currentStore={currentStore}
        shouldCloseOnOverlayClick={false}
        actions={actions}
        location={showLocationUpdateModal}
        posthog={posthog}
      />
    </>
  );
};

const getLocationsMarkup = (
  actions,
  locations,
  cartData,
  setShowLocationUpdateModal,
  posthog
) => {
  if (!locations) {
    return null;
  }
  // Sort locations alphabetically by city
  locations.sort((a, b) => {
    const cityA = a.content.nickname.toLowerCase();
    const cityB = b.content.nickname.toLowerCase();
    return cityA.localeCompare(cityB);
  });

  // Find the index of the location associated with the current store ID
  const currentIndex = locations.findIndex(
    (location) => location.content.id === cartData.store.id
  );

  // Move the location associated with the current store ID to the beginning
  if (currentIndex !== -1) {
    const currentLocation = locations.splice(currentIndex, 1);
    locations.unshift(currentLocation[0]);
  }

  return locations.map((location, key) => {
    return (
      <li
        key={`location--${location.content.id}-${location.content.address_1}-${key}`}
      >
        <Link
          to={`${location.url}`}
          className={LocationsStyles.title}
          style={{ color: "#345543", textDecoration: "underline" }}
          onClick={() => {
            capturePosthogLink({
              eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_LINK_CLICKED}`,
              title: location.content.city,
              to: location.url,
              posthog,
            });
          }}
        >
          {location.content.nickname}
        </Link>
        <a
          href={`${location.content.directions}`}
          target="_blank"
          rel="noreferrer"
          aria-label="Directions"
          style={{ color: "#345543", textDecoration: "underline" }}
        >
          <p className={LocationsStyles.location}>
            {location.content.address_1}
          </p>

          <p className={LocationsStyles.location}>
            {location.content.city}, {location.content.state}{" "}
            {location.content.zip_code}{" "}
          </p>
        </a>

        <p className={LocationsStyles.medical_and_rec}>
          {getStoreType(location)}
        </p>
        <div className={LocationsStyles.location_button_container}>
          {cartData.store.id !== location.content.id && (
            <Button
              theme="tgsSelect"
              key={`select-location--${location.content.id}`}
              label="Shop This Location"
              id={`locations-select-location--${location.content.id}`}
              onClick={(e) => {
                if (cartData.store.id === null) {
                  cartData.store.id = location.content.id;
                  cartData.store.name = location.title;
                  cartData.store.janeId =
                    location?.content?.vendor_integrations?.jane_rec_id;
                  cartData.products = [];
                  cartData.productsCount = 0;
                  actions.session.doSetCartData(cartData);

                  const link = `/shop/${location?.content?.id}`;
                  navigate(link);

                  capturePosthogLink({
                    eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_LINK_CLICKED}`,
                    title: "Shop This Location",
                    to: link,
                    posthog,
                  });
                } else {
                  setShowLocationUpdateModal(location);
                  capturePosthogEvent({
                    eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_CHANGE_MODAL_OPENED}`,
                    posthog,
                    title: "Shop This Location",
                    databag: { cartData },
                  });
                }
              }}
            />
          )}

          {cartData.store.id === location.content.id && (
            <Button
              theme="tgsSelect"
              key={`location-selected--${location.content.id}`}
              label="Shop Now"
              onClick={(e) => {
                const link = `/shop/${location.content.id}`;

                navigate(link);
                capturePosthogLink({
                  eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_LINK_CLICKED}`,
                  title: location.content.city + " View Menu",
                  to: link,
                  posthog,
                });
              }}
            />
          )}

          <Button
            theme="tgsSelectTransparent"
            key={`view-details--${location.content.id}`}
            id={`locations-view-details--${location.content.id}`}
            label="View Details"
            onClick={(e) => {
              const link = `/locations/${location.content.id}`;
              navigate(link);
              capturePosthogLink({
                eventName: `${posthogEvents.PRODUCT_FINDER} : ${posthogEvents.LOCATION_LINK_CLICKED}`,
                title: "View Details",
                to: link,
                location: location.content.city,
                posthog,
              });
            }}
          />
        </div>
      </li>
    );
  });
};

Locations.displayName = "Locations";

export default Locations;
